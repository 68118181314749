import React, { createContext, useState, useEffect } from "react";

export const GlobalStateContext = createContext();

const GlobalContextProvider = ({children}) => {
  const [state, setRequestDemoData] = useState({});
  const [showInfoBox, setShowInfoBox] = useState(false)

  useEffect(() => {
    const localStorageState = window.localStorage.getItem('showInfoBox')
    if (!localStorageState) {
      setShowInfoBox(true)
    }
    if (localStorageState !== null) setShowInfoBox(!!localStorageState)
    if (localStorageState === 'false') setShowInfoBox(false)
  }, [])

  useEffect(() => {
    window.localStorage.setItem('showInfoBox', showInfoBox)
  }, [showInfoBox])

  return <GlobalStateContext.Provider value={{state, setRequestDemoData, showInfoBox, setShowInfoBox}}>
    {children}
  </GlobalStateContext.Provider>
}

export default GlobalContextProvider;