import React from 'react';
import GlobalContextProvider from './src/context/context';

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};

export const shouldUpdateScroll = ({
  routerProps: {location}
}) => {
  if (location.hash === '') {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10)
    return false
  }
}
